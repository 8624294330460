import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MainVideoPlayer } from './MainVideoPlayer';
import { ThemeContext } from '../contexts/ThemeContext';
import { LanguageContext } from "../contexts/LocalizationContext/languages/LanguageContext";
import { Button, Grid, styled, Typography } from "@mui/material";
import { LayoutContext } from "../contexts/LayoutContext/LayoutContext";
import { BackgroundVideo } from "./BackgroundVideo";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import shelterCover from '../media/pictures/cits-shelter-cover.jpg';
import { Box } from "@mui/system";
import { socialMediaIcons } from "../displayConfig";
import { SpotifyIcon } from "../media/platform-icons/music-platforms/SpotifyIcon";
import paypalIcon from '../media/paypal_icon.png'
import HomebarGigSquare from '../media/pictures/gigs/Homebar-Gig-Square.jpg'
import {
    followOnFacebookCits,
    followOnInstagramCits,
    followOnTikTokCits,
    followOnTwitterCits,
    followOnYouTubeCits,
    PagesList,
    paypalDonateLink,
    spotifyCits,
} from "../config";
import { OgMetaTagsPages } from "./ReusableComponents/OgMetaTags";
import { CountdownToEvent } from "./CountdownToEvent/CountdownToEvent";
import PhotoGalleryBackground from "../media/pictures/gigs/PhotoGalleryBackground.jpg";
import { GigEvent } from "./GigEvent/GigEvent";

const MyButton = styled(Button)(({ theme }) => ({
    width: '25%',
    border: '2px solid white',
    borderColor: '#fff',
    [theme.breakpoints.up("md")]: {
        fontSize: 20
    },
    [theme.breakpoints.down("md")]: {
        width: '45%',
        opacity: 0.6,
    },
    [theme.breakpoints.down("sm")]: {
        width: '48%',
        opacity: 0.6,
        fontSize: 13,
    },
}));

const iconSize = 50;

const DonatePaypalButton = () => {
    const { isMobile } = useContext(LayoutContext);

    return (
        <Button
            variant="outlined"
            size="small"
            onClick={() => window.open(paypalDonateLink)}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#1f1f1f',
                color: '#fff',
                fontWeight: 'bolder',
                border: 'none',
                height: iconSize,
                width: iconSize,
                minWidth: iconSize,
                padding: 0,
                fontSize: 7,
                '& img': {
                    filter: 'grayscale(100%) invert(100%)',
                },
                '&:hover': {
                    backgroundColor: '#1f1f1f',
                    color: '#fff',
                    border: 'none',
                },
            }}
        >
            <img src={paypalIcon} alt="PayPal" style={{ width: 25, height: 25 }} />
            Donate
        </Button>
    );
}

const BandcampPlayer = ({ isMobile, trackId }) => {
    return (
        <iframe
            style={{ border: 0, width: isMobile? '100vw' : '700px', height: isMobile? '442px': '884px' }}
            src={`https://bandcamp.com/EmbeddedPlayer/track=${trackId}/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/`}
            seamless
        >
            <a href={`https://campingintheskies.bandcamp.com/track/${trackId}`}>Stuck In A Dream by Camping in the skies</a>
        </iframe>
    );
};

export const SocialMediaIconsRow = () => {
    const { isMobile } = useContext(LayoutContext);

    const socialMedia = [
        { icon: <SpotifyIcon size={iconSize} />, link: spotifyCits },
        { icon: socialMediaIcons.facebook, link: followOnFacebookCits },
        { icon: socialMediaIcons.instagram, link: followOnInstagramCits },
        { icon: socialMediaIcons.youTube, link: followOnYouTubeCits },
        { icon: socialMediaIcons.twitter, link: followOnTwitterCits },
        { icon: socialMediaIcons.tikTok, link: followOnTikTokCits },
        { component: <DonatePaypalButton /> },
    ];

    return (
        <Box
            sx={{
                width: isMobile ? '100%' : '50%',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 1
            }}
            zIndex={2}
        >
            {socialMedia.map((item, index) => (
                item.component ? (
                    <div key={index}>{item.component}</div>
                ) : (
                    <a href={item.link} target="_blank" rel="noopener noreferrer" key={index}>
                        {React.cloneElement(item.icon, { size: iconSize })}
                    </a>
                )
            ))}
        </Box>
    );
}

export const Homepage = ({ pageMeta }) => {
    const { theme } = useContext(ThemeContext);
    const { isMobile } = useContext(LayoutContext);
    const { dictionary } = useContext(LanguageContext);
    const location = useLocation();
    const navigate = useNavigate();
    const observerRef = useRef();

    // Define the target date and time
    const targetDate = new Date('2025-03-13T20:00:00+00:00'); // London time (UTC+0)

    // Get the current date and time
    const [currentDate, setCurrentDate] = useState(new Date());

    // Update the current date and time every second
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentDate(new Date());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    // Check if the current date is before or after the target date
    const isBeforeTargetDate = currentDate < targetDate;

    useEffect(() => {
        const elementId = location.hash.substring(1); // Remove the leading '#' from the hash
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location]);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const callback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    navigate(`#${entry.target.id}`, { replace: true });
                }
            });
        };

        observerRef.current = new IntersectionObserver(callback, options);

        const targets = document.querySelectorAll(
            '#album, #stories, #bandcamp-album-everything-starts, #watch-and-feel',
        );
        targets.forEach((target) => observerRef.current.observe(target));

        return () => {
            observerRef.current.disconnect();
        };
    }, [navigate]);

    return (
        <Box>
            <OgMetaTagsPages content={pageMeta} />
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                style={{ height: '100%' }}
            >
                {/* Conditionally render BandcampPlayer */}
                {!isBeforeTargetDate && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 6, width: "100%", height:"100%" }}>
                        <BandcampPlayer isMobile={isMobile} trackId="2446586234" />
                    </Box>
                )}
                {/* <GigEvent /> */}
            </Grid>
            <Box
                height={isMobile ? "50vh" : "100vh"}
                width="100vw"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{
                    backgroundImage: `url(${shelterCover})`,
                    backgroundSize: isMobile ? 'cover' : 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                }}
            >
                <SocialMediaIconsRow />
            </Box>

            <Box sx={{ width: '100%', height: '35vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography textAlign="center" variant="h5">
                    Alt rock band where land is no limit. currently recording their next release
                    <Link to={PagesList.aboutUs.route}> read more...</Link>
                </Typography>
            </Box>
        </Box>
    );
};
import AlbumCover from '../StramashGig13March25/AlbumCover.jpg'
import IMG1 from '../StramashGig13March25/01Stramash-campingintheskies-13mar25_90A2614-Enhanced-NR.JPG'
import IMG2 from '../StramashGig13March25/02Stramash-campingintheskies-13mar25_90A2621-Enhanced-NR.JPG'
import IMG3 from '../StramashGig13March25/480940966_10234197627218887_526269964239343472_n.jpg'
import IMG4 from '../StramashGig13March25/485069866_10234197627538895_6390456491122244415_n.jpg'
import IMG5 from '../StramashGig13March25/485356460_10234197627018882_85362295186333420_n.jpg'
import IMG6 from '../StramashGig13March25/CITS-10.jpg'
import IMG7 from '../StramashGig13March25/CITS-11.jpg'
import IMG8 from '../StramashGig13March25/CITS-12.jpg'
import IMG9 from '../StramashGig13March25/CITS-13.jpg'
import IMG10 from '../StramashGig13March25/CITS-14.jpg'
import IMG11 from '../StramashGig13March25/CITS-15.jpg'
import IMG12 from '../StramashGig13March25/CITS-16.jpg'
import IMG13 from '../StramashGig13March25/CITS-17.jpg'
import IMG14 from '../StramashGig13March25/CITS-18.jpg'
import IMG15 from '../StramashGig13March25/CITS-19.jpg'
import IMG16 from '../StramashGig13March25/CITS-2.jpg'
import IMG17 from '../StramashGig13March25/CITS-20.jpg'
import IMG18 from '../StramashGig13March25/CITS-21.jpg'
import IMG19 from '../StramashGig13March25/CITS-22.jpg'
import IMG20 from '../StramashGig13March25/CITS-23.jpg'
import IMG21 from '../StramashGig13March25/CITS-24.jpg'
import IMG22 from '../StramashGig13March25/CITS-25.jpg'
import IMG23 from '../StramashGig13March25/CITS-26.jpg'
import IMG24 from '../StramashGig13March25/CITS-27.jpg'
import IMG25 from '../StramashGig13March25/CITS-28.jpg'
import IMG26 from '../StramashGig13March25/CITS-29.jpg'
import IMG27 from '../StramashGig13March25/CITS-3.jpg'
import IMG28 from '../StramashGig13March25/CITS-30.jpg'
import IMG29 from '../StramashGig13March25/CITS-31.jpg'
import IMG30 from '../StramashGig13March25/CITS-32.jpg'
import IMG31 from '../StramashGig13March25/CITS-4.jpg'
import IMG32 from '../StramashGig13March25/CITS-5.jpg'
import IMG33 from '../StramashGig13March25/CITS-6.jpg'
import IMG34 from '../StramashGig13March25/CITS-7.jpg'
import IMG35 from '../StramashGig13March25/CITS-8.jpg'
import IMG36 from '../StramashGig13March25/CITS-9.jpg'
import IMG37 from '../StramashGig13March25/IMG_2031.jpg'
import IMG38 from '../StramashGig13March25/orngg.jpg'

export const StramashGig13March25List = [
  AlbumCover,
  IMG6,
  IMG7,
  IMG8,
  IMG9,
  IMG10,
  IMG11,
  IMG12,
  IMG13,
  IMG14,
  IMG15,
  IMG16,
  IMG17,
  IMG18,
  IMG19,
  IMG20,
  IMG21,
  IMG22,
  IMG23,
  IMG24,
  IMG25,
  IMG26,
  IMG27,
  IMG28,
  IMG29,
  IMG30,
  IMG31,
  IMG32,
  IMG33,
  IMG34,
  IMG35,
  IMG36,
  IMG37,
  IMG38,
  IMG1,
  IMG2,
  IMG3,
  IMG4,
  IMG5,
];
